import React from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import GetAdminAttachedToPartner from "./PartnerComponents/GetAdminAttachedToPartner";
import { colors, screens } from "../../utils";
import LearningStats from "./PartnerComponents/LearningStats";
import { IoSchoolOutline } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlinePayments } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import PartnerDashboardCard from "./PartnerComponents/PartnerDashboardCard";
import {
  useGetPartnerPaymentsQuery,
  useGetProvidersQuery,
  useGetSchoolsQuery,
  useGetUniversitiesQuery,
} from "../../app/services/partnerApi";
import Spinner from "../../utils/Spinner";

const PartnerScreens = () => {
  const { firstName, id } = useAppSelector(selectPartner);
  const { data: providersData, isLoading: providerIsLoading } =
    useGetProvidersQuery(id);
  const { data: schoolData, isLoading: schoolIsLoading } =
    useGetSchoolsQuery(id);

  const { data: paymentData } = useGetPartnerPaymentsQuery(id);
  const { data: universityData, isLoading: universityIsLoading } =
    useGetUniversitiesQuery(id);

  console.log(paymentData);

  // Dummy data for demonstration; replace with actual data as needed
  const stats = {
    numberOfSchools: 5,
    numberOfActivities: 10,
    totalPayments: 200,
  };

  return (
    <Container>
      <Wrapper>
        <StatsWrapper>
          <h1>Welcome to the dashboard, {firstName}</h1>
          <StatsContent>
            <h3>Your Stats</h3>
            <CardWrapper>
              <PartnerDashboardCard
                linkTo="schools"
                count={
                  schoolIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : (
                    schoolData && schoolData.numberOfSchools
                  )
                }
                text="Schools"
                icon={<IoSchoolOutline size={25} />}
              />
              <PartnerDashboardCard
                linkTo="providers"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : providersData ? (
                    providersData.length
                  ) : (
                    "0"
                  )
                }
                text="Providers"
                icon={<RiCustomerService2Line size={25} />}
              />
              <PartnerDashboardCard
                linkTo="payments"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : paymentData ? (
                    paymentData.totalPayments
                  ) : (
                    "0"
                  )
                }
                text="Payments"
                icon={<MdOutlinePayments size={25} />}
              />
              <PartnerDashboardCard
                linkTo="payments"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : paymentData ? (
                    paymentData.totalPayments * 0.1
                  ) : (
                    "0"
                  )
                }
                text="Commissions (10%)"
                icon={<GiMoneyStack size={25} />}
              />
            </CardWrapper>
          </StatsContent>

          <StatsContent>
            <h3>Learning Resources</h3>
            <LearningStats />
          </StatsContent>
        </StatsWrapper>
        <RepWrapper>
          <GetAdminAttachedToPartner />
        </RepWrapper>
      </Wrapper>
    </Container>
  );
};

export default PartnerScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const StatsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.7rem;

  &:first-of-type {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;

  & > * {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }

  @media (max-width: ${screens.tab}) {
    & > * {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const RepWrapper = styled.div`
  border-left: 1px solid ${colors.primary};
  width: 35%;
  display: flex;

  @media (max-width: ${screens.tab}) {
    border-left: none;
    width: 100%;
  }
`;
