import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

//Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}admins/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.admin.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Admin"],
  endpoints: (builder) => ({
    // Login a Partner
    loginAdmin: builder.mutation({
      query: (data) => {
        return {
          url: "admin-signin",
          method: "POST",
          body: data,
        };
      },
    }),

    // Add an Admin
    addAdmin: builder.mutation({
      query: (data) => ({
        url: "add-admin",
        method: "POST",
        body: data,
      }),
    }),

    //Get all providers
    getAllProviders: builder.query({
      query: () => "providers",
      providesTags: ["Admin"],
    }),

    // Get schools
    getAllSchools: builder.query({
      query: () => "schools",
      providesTags: ["Admin"],
    }),

    // Get admins
    getAllAdmins: builder.query({
      query: () => "admins",
      providesTags: ["Admin"],
    }),

    // Get partners
    getAllPartners: builder.query({
      query: () => "partners",
      providesTags: ["Admin"],
    }),

    // Get universities
    getAllUniversities: builder.query({
      query: () => "universities",
      providesTags: ["Admin"],
    }),

    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Get a single admin by ID
    getAdminById: builder.query({
      query: (id) => `admins/${id}`,
      providesTags: ["Admin"],
    }),

    // Get a single partner by ID
    getPartnerById: builder.query({
      query: (id) => `partners/${id}`,
      providesTags: ["Admin"],
    }),

    // Get a single provider by ID
    getProviderById: builder.query({
      query: (id) => `providers/${id}`,
      providesTags: ["Admin"],
    }),

    // Get a single school by ID
    getSchoolById: builder.query({
      query: (id) => `schools/${id}`,
      providesTags: ["Admin"],
    }),

    // Get a single university by ID
    getUniversityById: builder.query({
      query: (id) => `universities/${id}`,
      providesTags: ["Admin"],
    }),

    // Delete an admin by ID
    deleteAdminById: builder.mutation({
      query: (id) => ({
        url: `admins/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Delete a partner by ID
    deletePartnerById: builder.mutation({
      query: (id) => ({
        url: `partners/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Delete a provider by ID
    deleteProviderById: builder.mutation({
      query: (id) => ({
        url: `providers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Delete a school by ID
    deleteSchoolById: builder.mutation({
      query: (id) => ({
        url: `schools/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Delete a university by ID
    deleteUniversityById: builder.mutation({
      query: (id) => ({
        url: `universities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Assign an admin to a partner
    assignAdminToPartner: builder.mutation({
      query: ({ adminId, partnerId }) => ({
        url: `partners/${partnerId}/assign-admin`,
        method: "PATCH",
        body: { adminId },
      }),
      invalidatesTags: ["Admin"],
    }),

    // Assign an admin to a provider
    assignAdminToProvider: builder.mutation({
      query: ({ adminId, providerId }) => ({
        url: `providers/${providerId}/assign-admin`,
        method: "PATCH",
        body: { adminId },
      }),
      invalidatesTags: ["Admin"],
    }),

    // Assign an admin to a university
    assignAdminToUniversity: builder.mutation({
      query: ({ adminId, universityId }) => ({
        url: `universities/${universityId}/assign-admin`,
        method: "PATCH",
        body: { adminId },
      }),
      invalidatesTags: ["Admin"],
    }),

    // Update Password with Reset Code
    defaultPayment: builder.mutation({
      query: (data) => ({
        url: "/subscription-price",
        method: "POST",
        body: data,
      }),
    }),

    // Get universities
    getDefaultPrice: builder.query({
      query: () => "default-payments",
      providesTags: ["Admin"],
    }),

    // Get payment sum
    getPaymentsSum: builder.query({
      query: () => "get-all-payments",
      providesTags: ["Admin"],
    }),

    //Delete an activity
    deleteActivityAdminById: builder.mutation({
      query: (activityId) => ({
        url: `activity/${activityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admin"],
    }),

    // Get payment sum
    getAllAdminActivities: builder.query({
      query: () => "activities",
      providesTags: ["Admin"],
    }),

    // Get a single Activity by ID
    getActivityAdminById: builder.query({
      query: (id) => `activities/${id}`,
      providesTags: ["Admin"],
    }),

    // Get approved activities
    getApprovedActivities: builder.query({
      query: () => "activities/approved",
      providesTags: ["Admin"],
    }),

    // Get unapproved activiies
    getUnapprovedActivities: builder.query({
      query: () => "activities/unapproved",
      providesTags: ["Admin"],
    }),

    //approve an activity
    approveActivity: builder.mutation({
      query: (activityId) => ({
        url: `activities/${activityId}/approve`,
        method: "PUT",
      }),
      invalidatesTags: ["Admin"],
    }),

    //...
  }),
});

export const {
  useAddAdminMutation,
  useLoginAdminMutation,
  useGetAllProvidersQuery,
  useGetAllSchoolsQuery,
  useGetAllAdminsQuery,
  useGetAllPartnersQuery,
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useGetAdminByIdQuery,
  useGetPartnerByIdQuery,
  useGetProviderByIdQuery,
  useGetSchoolByIdQuery,
  useDeleteAdminByIdMutation,
  useDeletePartnerByIdMutation,
  useDeleteProviderByIdMutation,
  useDeleteSchoolByIdMutation,
  useAssignAdminToPartnerMutation,
  useGetAllUniversitiesQuery,
  useGetUniversityByIdQuery,
  useDeleteUniversityByIdMutation,
  useAssignAdminToUniversityMutation,
  useDefaultPaymentMutation,
  useGetDefaultPriceQuery,
  useAssignAdminToProviderMutation,
  useGetPaymentsSumQuery,
  useGetActivityAdminByIdQuery,
  useGetAllAdminActivitiesQuery,
  useDeleteActivityAdminByIdMutation,
  useGetApprovedActivitiesQuery,
  useGetUnapprovedActivitiesQuery,
  useApproveActivityMutation,
} = adminApi;
