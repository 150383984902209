import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URI as string;

export const providerApi = createApi({
  reducerPath: "providerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}providers/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.provider.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Provider"],
  endpoints: (builder) => ({
    // Create a new Provider
    signupProvider: builder.mutation({
      query: (data) => {
        return {
          url: "provider-signup",
          method: "POST",
          body: data,
        };
      },
    }),

    //Update Avatar
    updateAvatar: builder.mutation({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/${id}/update-avatar`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Provider"],
    }),

    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Login a Provider
    signInProvider: builder.mutation({
      query: (data) => {
        return {
          url: "provider-signin",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new activity
    createActivity: builder.mutation({
      query: ({ providerId, activityData }) => ({
        url: `${providerId}/create-activity`,
        method: "POST",
        body: activityData,
      }),
      invalidatesTags: ["Provider"],
    }),

    //Get all activities
    getAllActivities: builder.query({
      query: (providerId) => `${providerId}/activities`,
    }),

    //Get a single activities
    getOneActivity: builder.query({
      query: ({ providerId, activityId }) =>
        `${providerId}/activity/${activityId}`,
    }),

    //Get a single activities
    getSchoolsEnrolledToActivity: builder.query({
      query: ({ providerId, activityId }) =>
        `${providerId}/activity/${activityId}/enrolled-schools`,
    }),

    // Define the create schedule mutation
    createSchedule: builder.mutation({
      query: (scheduleData) => ({
        url: "/create-schedule",
        method: "POST",
        body: scheduleData,
      }),
      invalidatesTags: ["Provider"],
    }),

    // Define the createOnlineMeeting mutation
    createOnlineMeeting: builder.mutation({
      query: ({ scheduleId, meetingData }) => ({
        url: `/${scheduleId}/online`,
        method: "PATCH",
        body: meetingData, // This will include duration and timeZone
      }),
      invalidatesTags: ["Provider"],
    }),

    //Get a single schedule by id
    getOneSchedule: builder.query({
      query: (scheduleId) => `schedules/${scheduleId}`,
    }),

    //Get a provider's schedules by id
    getProviderSchedules: builder.query({
      query: (providerId) => `schedules/providers/${providerId}`,
    }),

    //Get a provider's schedules by id
    getProviderData: builder.query({
      query: (providerId) => `provider/${providerId}/activities`,
    }),

    //Get a provider's activities students
    getProviderActivitiesSchools: builder.query({
      query: (providerId) => `provider/${providerId}/schools`,
    }),

    //Get a provider's activities students
    getProviderActivitiesStudents: builder.query({
      query: (providerId) => `provider/${providerId}/students`,
    }),

    //Get a provider's activities students
    getActivityStudents: builder.query({
      query: ({ providerId, activityId }) =>
        `provider/${providerId}/activity/${activityId}/student-details`,
    }),

    // Mark activity register
    markProviderRegister: builder.mutation({
      query: ({
        providerId,
        activityId,
        scheduleId,
        studentId,
        registerData,
      }) => ({
        url: `${providerId}/activities/${activityId}/schedule/${scheduleId}/students/${studentId}/mark-register`,
        method: "POST",
        body: registerData,
      }),
      invalidatesTags: ["Provider"],
    }),

    // ...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useSignInProviderMutation,
  useSignupProviderMutation,
  useUpdateAvatarMutation,
  useCreateActivityMutation,
  useGetAllActivitiesQuery,
  useGetOneActivityQuery,
  useGetSchoolsEnrolledToActivityQuery,
  useCreateScheduleMutation,
  useCreateOnlineMeetingMutation,
  useGetOneScheduleQuery,
  useGetProviderSchedulesQuery,
  useGetProviderDataQuery,
  useGetProviderActivitiesSchoolsQuery,
  useGetProviderActivitiesStudentsQuery,
  useGetActivityStudentsQuery,
  useMarkProviderRegisterMutation,
} = providerApi;
